import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/vincepicone/Programming/Work/carbon-website/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import Preview from 'components/Preview';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const Caption = makeShortcode("Caption");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h3 {...{
      "id": "now-that-we-have-a-vue-app-using-the-ui-shell-its-time-to-build-a-few-static-pages-in-this-step-well-become-comfortable-with-the-carbon-grid-and-various-carbon-components"
    }}>{`Now that we have a Vue app using the UI Shell, it’s time to build a few static pages. In this step, we’ll become comfortable with the Carbon grid and various Carbon components.`}</h3>
    <AnchorLinks mdxType="AnchorLinks">
      <AnchorLink mdxType="AnchorLink">Fork, clone and branch</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Install grid</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Add landing page grid</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Build landing page</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Style landing page</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Add repo page grid</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Build repo page</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Style repo page</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Submit pull request</AnchorLink>
    </AnchorLinks>
    <h2 {...{
      "id": "preview"
    }}>{`Preview`}</h2>
    <p>{`A `}<a parentName="p" {...{
        "href": "https://vue-step-3--carbon-tutorial-vue.netlify.com"
      }}>{`preview`}</a>{` of what you’ll build:`}</p>
    <Preview height="400" title="Carbon Tutorial Step 2" src="https://vue-step-3--carbon-tutorial-vue.netlify.com" frameBorder="no" allowtransparency="true" allowFullScreen="true" className="bx--iframe bx--iframe--border" mdxType="Preview" />
    <h2 {...{
      "id": "fork-clone-and-branch"
    }}>{`Fork, clone and branch`}</h2>
    <p>{`This tutorial has an accompanying GitHub repository called `}<a parentName="p" {...{
        "href": "https://github.com/carbon-design-system/carbon-tutorial-vue"
      }}>{`carbon-tutorial-vue`}</a>{` that we’ll use as a starting point for each step. If you haven’t forked and cloned that repository yet, and haven’t added the upstream remote, go ahead and do so by following the `}<a parentName="p" {...{
        "href": "/tutorial/vue/step-1#fork-clone--branch"
      }}>{`step 1 instructions`}</a>{`.`}</p>
    <h3 {...{
      "id": "branch"
    }}>{`Branch`}</h3>
    <p>{`With your repository all set up, let’s check out the branch for this tutorial step’s starting point.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ git fetch upstream
$ git checkout -b vue-step-2 upstream/vue-step-2
`}</code></pre>
    <p><em parentName="p">{`Note: This builds on top of step 1, but be sure to check out the upstream step 2 branch because it includes the static assets and fixes required to get through this step.`}</em></p>
    <h3 {...{
      "id": "build-and-start-app"
    }}>{`Build and start app`}</h3>
    <p>{`Install the app’s dependencies (in case you’re starting fresh in your current directory and not continuing from the previous step):`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ yarn
`}</code></pre>
    <p>{`Then, start the app:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ yarn serve
`}</code></pre>
    <p>{`You should see something similar to where the `}<a parentName="p" {...{
        "href": "/tutorial/vue/step-1"
      }}>{`previous step`}</a>{` left off.`}</p>
    <h2 {...{
      "id": "install-grid"
    }}>{`Install grid`}</h2>
    <p>{`In our last step we added our styles, component and icon packages. Now that we’re building the pages with grid, we need to install one more Carbon package. Stop your development environment (`}<inlineCode parentName="p">{`CTRL-C`}</inlineCode>{`) and:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ yarn add @carbon/grid
`}</code></pre>
    <p>{`In `}<inlineCode parentName="p">{`_carbon.scss`}</inlineCode>{`, we need to configure our grid to use 16 columns instead of the default 12 columns. We do this by adding `}<inlineCode parentName="p">{`grid-columns-16: true`}</inlineCode>{` in our `}<inlineCode parentName="p">{`$feature-flags`}</inlineCode>{`.`}</p>
    <h5 {...{
      "id": "srcstyles_carbonscss"
    }}>{`src/styles/`}{`_`}{`carbon.scss`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`$feature-flags: (
  grid-columns-16: true
);
`}</code></pre>
    <p><em parentName="p">{`Note: Like before, the feature flag still needs to come before the Carbon`}</em>{` `}<inlineCode parentName="p">{`styles.scss`}</inlineCode>{` `}<em parentName="p">{`import.`}</em></p>
    <p>{`Run `}<inlineCode parentName="p">{`yarn serve`}</inlineCode>{` so we can begin building.`}</p>
    <h2 {...{
      "id": "add-landing-page-grid"
    }}>{`Add landing page grid`}</h2>
    <p>{`Let’s add our grid elements to `}<inlineCode parentName="p">{`LandingPage.vue`}</inlineCode>{`.`}</p>
    <p>{`In order to use the grid, we need to wrap everything in a `}<inlineCode parentName="p">{`<div class="bx--grid">`}</inlineCode>{`. We can continue to make rows by adding a `}<inlineCode parentName="p">{`<div class="bx--row">`}</inlineCode>{` inside the grid, as well as make columns within those rows by adding `}<inlineCode parentName="p">{`<div class="bx--col-[breakpoint]-[size]">`}</inlineCode>{`.`}</p>
    <p>{`Our column sizes are specified by the number of columns they’ll be spanning. If we use `}<inlineCode parentName="p">{`bx--col-lg-4`}</inlineCode>{`, it means it’ll span 4 of the 16 columns. If we use `}<inlineCode parentName="p">{`bx--col-lg-8`}</inlineCode>{` it means it’ll span 8 of the 16 columns, and so on.`}</p>
    <p>{`We’ve included the designs for this tutorial app in the `}<inlineCode parentName="p">{`design.sketch`}</inlineCode>{` file found as a top-level file in the `}<inlineCode parentName="p">{`carbon-tutorial-vue`}</inlineCode>{` repository. But, if you don’t have Sketch installed and available to inspect the design, we’ll provide screenshots.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "76.41921397379913%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAAsSAAALEgHS3X78AAADAklEQVQ4y3VUyW7TUBT1F/ABbPgH2CBW7NnBmiVCQuxhhZBYQeeKNJ1ICxIrWlFaVQJUlVaphKIOCR2UJk1ip3GaxPMQx44zHO57bssgWBzfc5/eO77P91wLyc1NxAcHMTcRQ4zi1NgYpkZHOX/NMDDwB2L/4BPDw4gPDeHz6iqEHzs7mB+J4cPUDBbicSxMT2NhchJLMzNYnp/HciIRYW6OY+W3/IKvsH2zs0glkxB6QQC7JMItn8I7PUWrWuWxU68DrgtYVgTbBhzn/9w0gXYbQkCJX6kgIKEWRU+Wo0iiLqFZLkcgbhZL9HIJjlSGLUl83RYlGFSQLYrwSFTo+D6CszMEtRo6qoqerqOnaREMA12K3fN1r6ageabAq2sIGgo6ikqVESwNMHR0W61I0CqVYNIbQkVBl0SYcPdckF+F4fLqxB2KrsN5Oqvh0VcJS1kR/XYAoe15KO6mcbKzBymTgZhOU76LwvY28gSfKmdCPYOEbAt2Q4MkKyhVqDJae7qoQniZwfWP32B7TdYUH+HpMTpyDkYhD6VQgHqSh03fx6XvySrtMzHHRFUycWtaxNXRAq7EjnBv7TvGN+oQRg/xcDMDdEMIuhXi2TsbTxIO5BIddK3oqux6593jOQlCdXDjTQXCqxyE8QPcXUthPkkVTmRxP7kPhNTllhdiI2ViPWXBqUXXYhX1SaRvRhGmgaZiYi2r4O2ejOfrMm6+z2PyoIijnIU7n0qYIs4F2QOeDvisKhJglZEAFyTOmgTXxLcMWWLsGA+29jH+haoaOcTt1S2I5TqSORUluRb5sEePrkYt181LER7PeXR9A5WyiRebMpZyEhZTBq4lsniczKBn6tHnoX1sSIR+GP6yxIXz/+bMNsQ9PXpB37CwK2rQVYOv9y2bd7zPKgzJNszxzNwhWSRsNDhvUYfbNH58jeDTJAVn1ShnY6k2EFZlvo+dCelMu0m2CWhejWKRW4R5rkWbm8SZbVosZ+IEh8bRonnnOa27FC0aQYfNP51h/wCfXPETNXAozX9CobYAAAAASUVORK5CYII=')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Landing page grid",
        "title": "Landing page grid",
        "src": "/static/7f74bf609153609bb56b804b63244724/fb070/landing-layout.png",
        "srcSet": ["/static/7f74bf609153609bb56b804b63244724/d6747/landing-layout.png 288w", "/static/7f74bf609153609bb56b804b63244724/09548/landing-layout.png 576w", "/static/7f74bf609153609bb56b804b63244724/fb070/landing-layout.png 1152w", "/static/7f74bf609153609bb56b804b63244724/fb104/landing-layout.png 1728w", "/static/7f74bf609153609bb56b804b63244724/902fb/landing-layout.png 2304w", "/static/7f74bf609153609bb56b804b63244724/1d306/landing-layout.png 2748w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px",
        "loading": "lazy"
      }}></img>{`
    `}</span>
    <Caption mdxType="Caption">Landing page grid</Caption>
    <p><em parentName="p">{`Pro tip:`}</em>{` `}<inlineCode parentName="p">{`CTRL-L`}</inlineCode>{` `}<em parentName="p">{`toggles the layout in Sketch.`}</em></p>
    <p>{`We’ll break this down into three rows. The first row with the gray background doesn’t appear to need any columns. The second row with the white background looks like it has two columns of different widths. The third row with the gray background looks like it has four columns of equal width.`}</p>
    <p>{`We’ll make rows like so:`}</p>
    <h5 {...{
      "id": "srcviewslandingpagelandingpagevue"
    }}>{`src/views/LandingPage/LandingPage.vue`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<template>
  <div class="bx--grid bx--grid--full-width landing-page">
    <div class="bx--row landing-page__banner">
      <div class="bx--col-lg-16">1</div>
    </div>
    <div class="bx--row landing-page__r2">
      <div class="bx--col-md-4 bx--col-lg-7">7/16</div>
      <div class="bx--col-md-4 bx--offset-lg-1 bx--col-lg-8">8/16</div>
    </div>
    <div class="bx--row landing-page__r3">
      <div class="bx--col-md-4 bx--col-lg-4">1/4</div>
      <div class="bx--col-md-4 bx--col-lg-4">1/4</div>
      <div class="bx--col-md-4 bx--col-lg-4">1/4</div>
      <div class="bx--col-md-4 bx--col-lg-4">1/4</div>
    </div>
  </div>
</template>
`}</code></pre>
    <p>{`We added a class of `}<inlineCode parentName="p">{`bx--grid--full-width`}</inlineCode>{` to the grid container since our rows need to expand the whole page without any margins. We also added some custom classes like `}<inlineCode parentName="p">{`landing-page`}</inlineCode>{`, `}<inlineCode parentName="p">{`landing-page__banner`}</inlineCode>{`, `}<inlineCode parentName="p">{`landing-page__r2`}</inlineCode>{`, etc., which we will use later.`}</p>
    <p>{`Also, take notice of the second row. The tab content only covers 7 columns at this large viewport to prevent overly-large line lengths, so we needed to add a 1 column offset `}<inlineCode parentName="p">{`bx--offset-lg-1`}</inlineCode>{` to second column to fill the full 16 columns in the grid. Then, both of those columns have `}<inlineCode parentName="p">{`bx--col-md-4`}</inlineCode>{` classes so they are of equal width at medium-sized viewports.`}</p>
    <h2 {...{
      "id": "build-landing-page"
    }}>{`Build landing page`}</h2>
    <p>{`We’ll start adding HTML elements and components by row.`}</p>
    <h3 {...{
      "id": "first-row"
    }}>{`First row`}</h3>
    <p>{`In our first row we’ll use a `}<inlineCode parentName="p">{`CvBreadcrumb`}</inlineCode>{` component.`}</p>
    <p>{`We can now add our component to the first row, along with a header, like so:`}</p>
    <h5 {...{
      "id": "srcviewslandingpagelandingpagevue-1"
    }}>{`src/views/LandingPage/LandingPage.vue`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div class="bx--row landing-page__banner">
  <div class="bx--col-lg-16">
    <cv-breadcrumb noTrailingSlash>
      <cv-breadcrumb-item>
        <cv-link href="/">Getting started</cv-link>
      </cv-breadcrumb-item>
    </cv-breadcrumb>
    <h1 class="landing-page__heading">Design &amp; build with Carbon</h1>
  </div>
</div>
`}</code></pre>
    <p>{`You may notice that the styles look off. Don’t worry, we’ll fix these later.`}</p>
    <h3 {...{
      "id": "second-row"
    }}>{`Second row`}</h3>
    <p>{`In our second row we’ll use `}<inlineCode parentName="p">{`CvTabs`}</inlineCode>{` and `}<inlineCode parentName="p">{`CvButton`}</inlineCode>{` components.`}</p>
    {
      /* ##### src/views/LandingPage/LandingPage.vue
      ```javascript
      const props = {
       tabs: {
         selected: 0,
         triggerHref: "#",
         role: "navigation"
       },
       tab: {
         href: "#",
         role: "presentation",
         tabIndex: 0
       }
      };
      ``` */
    }
    <p>{`Modify the second row to use the `}<inlineCode parentName="p">{`Tab`}</inlineCode>{` component.`}</p>
    <h5 {...{
      "id": "srcviewslandingpagelandingpagevue-2"
    }}>{`src/views/LandingPage/LandingPage.vue`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div class="bx--row landing-page__r2">
  <div class="bx--col bx--no-gutter">
    <cv-tabs selected="0">
      <cv-tab label="About">
        <div class="bx--grid bx--grid--no-gutter bx--grid--full-width">
          <div class="bx--row landing-page__tab-content">
            <div class="bx--col-md-4 bx--col-lg-7">7/16</div>
            <div class="bx--col-md-4 bx--offset-lg-1 bx--col-lg-8">
              8/16
            </div>
          </div>
        </div>
      </cv-tab>
      <cv-tab label="Design">
        <div class="bx--grid bx--grid--no-gutter bx--grid--full-width">
          <div class="bx--row landing-page__tab-content">
            <div class="bx--col-lg-16">
              Rapidly build beautiful and accessible experiences. The Carbon kit
              contains all resources you need to get started.
            </div>
          </div>
        </div>
      </cv-tab>
      <cv-tab label="Develop">
        <div class="bx--grid bx--grid--no-gutter bx--grid--full-width">
          <div class="bx--row landing-page__tab-content">
            <div class="bx--col-lg-16">
              Carbon provides styles and components in Vanilla, Vue, Angular,
              and Vue for anyone building on the web.
            </div>
          </div>
        </div>
      </cv-tab>
    </cv-tabs>
  </div>
</div>
`}</code></pre>
    <p><em parentName="p">{`Note: We’re using the grid for the page layout, but we also need to apply the grid within the tab content. When doing so, make sure the nested grid has the expected`}</em>{` `}<inlineCode parentName="p">{`grid`}</inlineCode>{` `}<em parentName="p">{`>`}</em>{` `}<inlineCode parentName="p">{`row`}</inlineCode>{` `}<em parentName="p">{`>`}</em>{` `}<inlineCode parentName="p">{`col`}</inlineCode>{` `}<em parentName="p">{`DOM structure.`}</em></p>
    <p>{`Hold up! If you were to run `}<a parentName="p" {...{
        "href": "https://www.ibm.com/able/dynamic-assessment-plug-in.html"
      }}>{`DAP`}</a>{` to check for accessibility violations, you’d see `}<inlineCode parentName="p">{`Multiple navigation landmarks must have unique labels specified with aria-label or aria-labelledby`}</inlineCode>{` because both the `}<inlineCode parentName="p">{`CvBreadcrumb`}</inlineCode>{` and `}<inlineCode parentName="p">{`CvTabs`}</inlineCode>{` components use `}<inlineCode parentName="p">{`<nav>`}</inlineCode>{` elements. To fix, add `}<inlineCode parentName="p">{`aria-label`}</inlineCode>{` to the `}<inlineCode parentName="p">{`CvBreadcrumb`}</inlineCode>{` opening tag:`}</p>
    {
      /* prettier-ignore-start */
    }
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<cv-breadcrumb noTrailingSlash aria-label="Page navigation">
`}</code></pre>
    {
      /* prettier-ignore-end */
    }
    <p>{`Same goes for the `}<inlineCode parentName="p">{`CvTabs`}</inlineCode>{` opening tag:`}</p>
    {
      /* prettier-ignore-start */
    }
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<cv-tabs selected="0" aria-label="Tab navigation">
`}</code></pre>
    {
      /* prettier-ignore-end */
    }
    <p>{`Give yourself a pat on the back if you actually ran the DAP tool. We’ll install the DAP tool in a later step, so don’t worry if you didn’t.`}</p>
    <p>{`Next, we’ll need to add a styling override to move the tabs to the right on large viewports. Create a file `}<inlineCode parentName="p">{`_carbon-overrides.scss`}</inlineCode>{` in `}<inlineCode parentName="p">{`src/views/LandingPage`}</inlineCode>{` with this declaration block.`}</p>
    <h5 {...{
      "id": "srcviewslandingpage_carbon-overridesscss"
    }}>{`src/views/LandingPage/`}{`_`}{`carbon-overrides.scss`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-scss"
      }}>{`.landing-page__r2 .bx--tabs__nav {
  right: 0;
}
`}</code></pre>
    <p>{`Then in `}<inlineCode parentName="p">{`LandingPage.vue`}</inlineCode>{` add a style section with this import.`}</p>
    <h5 {...{
      "id": "srcviewslandingpagelandingpagevue-3"
    }}>{`src/views/LandingPage/LandingPage.vue`}</h5>
    {
      /* prettier-ignore-start */
    }
    <pre><code parentName="pre" {...{
        "className": "language-scss"
      }}>{`<style lang="scss">
@import "./carbon-overrides";
</style>
`}</code></pre>
    {
      /* prettier-ignore-end */
    }
    <p><em parentName="p">{`Note: We don’t have to include this in a separate file, but it’s nice to keep overrides separate from your`}</em>{`
application’s styling so when migrating to future Carbon versions and if there are breaking changes via different class
names, you have a consolidated list of styling declaration blocks to review.`}<em parentName="p">{` We can now add our images and text for
each column in the first `}<inlineCode parentName="em">{`CvTab`}</inlineCode>{` in `}<inlineCode parentName="em">{`LandingPage.vue`}</inlineCode>{`.`}</em></p>
    <h5 {...{
      "id": "srcviewslandingpagelandingpagevue-4"
    }}>{`src/views/LandingPage/LandingPage.vue`}</h5>
    {
      /* prettier-ignore-start */
    }
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<cv-tab label="About">
  <div class="bx--grid bx--grid--no-gutter bx--grid--full-width">
    <div class="bx--row landing-page__tab-content">
      <div class="bx--col-md-4 bx--col-lg-7">
        <h2 class="landing-page__subheading">What is Carbon?</h2>
        <p class="landing-page__p">
          Carbon is IBM’s open-source design system for digital
          products and experiences. With the IBM Design Language as
          its foundation, the system consists of working code, design
          tools and resources, human interface guidelines, and a
          vibrant community of contributors.
        </p>
        <cv-button>Learn more</cv-button>
      </div>
      <div class="bx--col-md-4 bx--offset-lg-1 bx--col-lg-8">
        <img
          class="landing-page__illo"
          src="../../assets/tab-illo.png"
          alt="Carbon illustration"
        />
      </div>
    </div>
  </div>
</cv-tab>
`}</code></pre>
    {
      /* prettier-ignore-end */
    }
    <p>{`Now let’s set the image size in the style section of `}<inlineCode parentName="p">{`LandingPage.vue`}</inlineCode>{`:`}</p>
    <h5 {...{
      "id": "srcviewslandingpagelandingpagevue-5"
    }}>{`src/views/LandingPage/LandingPage.vue`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-scss"
      }}>{`.landing-page__illo {
  max-width: 100%;
}
`}</code></pre>
    <p>{`Assuming that the second and third tab would have a similar design, we would set them up in the same way. However, since our design specs don’t show those tabs, we’ll leave the code as is.`}</p>
    <h3 {...{
      "id": "third-row"
    }}>{`Third row`}</h3>
    <p>{`The third row will be created in a later tutorial, so we’ll just add the headers for now.`}</p>
    <h5 {...{
      "id": "srcviewslandingpagelandingpagevue-6"
    }}>{`src/views/LandingPage/LandingPage.vue`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div class="bx--row landing-page__r3">
  <div class="bx--col-md-4 bx--col-lg-4">
    <h3 class="landing-page__label">The Principles</h3>
  </div>
  <div class="bx--col-md-4 bx--col-lg-4">Carbon is Open</div>
  <div class="bx--col-md-4 bx--col-lg-4">Carbon is Modular</div>
  <div class="bx--col-md-4 bx--col-lg-4">Carbon is Consistent</div>
</div>
`}</code></pre>
    <h2 {...{
      "id": "style-landing-page"
    }}>{`Style landing page`}</h2>
    <p>{`We’ve added basic layout styles in `}<inlineCode parentName="p">{`LandingPage.vue`}</inlineCode>{`, so now let’s add type, color and spacing styles to match the design. We’ll be using our `}<a parentName="p" {...{
        "href": "https://www.carbondesignsystem.com/guidelines/spacing"
      }}>{`spacing tokens`}</a>{`. In a new file `}<inlineCode parentName="p">{`src/styles/_carbon-utils.scss`}</inlineCode>{`, add these imports at the `}<strong parentName="p">{`top`}</strong>{` of the file so we can use Carbon breakpoints, tokens, and typography Sass mixins and functions:`}</p>
    <h5 {...{
      "id": "srcstyles_carbon-utilsscss"
    }}>{`src/styles/`}{`_`}{`carbon-utils.scss`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-scss"
      }}>{`@import 'carbon-components/scss/globals/scss/vendor/@carbon/type/scss/font-family.scss';
@import 'carbon-components/scss/globals/scss/vendor/@carbon/layout/scss/breakpoint.scss';
@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/vars.scss';
`}</code></pre>
    <p>{`Adding these tokesn, mixins etc. here means we can import them with a single line into any component that needs them.`}</p>
    <h3 {...{
      "id": "banner"
    }}>{`Banner`}</h3>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "42.79411764705882%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAAAsSAAALEgHS3X78AAABa0lEQVQoz53QvUrDUBQH8LyLrYPiRwMqGMTBRX2Dbg4iYttExUFrdXRy9RV8AkGwWuznoIhaBXFQhzY3bdomaZNIWrV/b5NQWm1BHH6cy/+eezhcxjRNKGUFuq7DsizbR6PR1qDqNGvU6115P8yy348ZdgTz3DQWOA5zE5Pweby28QEPpoaGMetjwY2OgfUOtu9+Yt3KHB0cYnVRQETYQji0iX1hA+FA0BVAJMTTbB17PI/doJPvrAV62qYYVA0UnlXIORUVUoVVUgBNw5eiotmqqorPioKm6pxbGXQDMEzK6Ea/j6kRgttoAtfnUWSTcdzFE3jMZHAVjdo1m0zZ9T6ZxE0shod0GpXXNxiiiFouj1q+kwjGlGRc8CpeToowpDxtlChiM4lLcjPiMInUG+1j3mUZYqqIsxUNJC3DqtAHonNp62j+lfUa2NowJih4Oi7Z2/Vt/gtnwyLK2QJOlzTkLouwFPL/wXTgN1/aVynlXSGtAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Banner vertical spacing",
            "title": "Banner vertical spacing",
            "src": "/static/13033ffbcd2fa2128eac6e3c8bef7ea7/fb070/landing-r1-spacing.png",
            "srcSet": ["/static/13033ffbcd2fa2128eac6e3c8bef7ea7/d6747/landing-r1-spacing.png 288w", "/static/13033ffbcd2fa2128eac6e3c8bef7ea7/09548/landing-r1-spacing.png 576w", "/static/13033ffbcd2fa2128eac6e3c8bef7ea7/fb070/landing-r1-spacing.png 1152w", "/static/13033ffbcd2fa2128eac6e3c8bef7ea7/55534/landing-r1-spacing.png 1360w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
        <Caption mdxType="Caption">Banner vertical spacing</Caption>
      </Column>
    </Row>
    <p><em parentName="p">{`Pro tip:`}</em>{` `}<inlineCode parentName="p">{`CTRL-G`}</inlineCode>{` `}<em parentName="p">{`toggles the grid in Sketch.`}</em></p>
    <p>{`Back to `}<inlineCode parentName="p">{`LandingPage.vue`}</inlineCode>{`, we need to add space above the breadcrumb and below the heading. For that, add:`}</p>
    <h5 {...{
      "id": "srcviewslandingpagelandingpagevue-7"
    }}>{`src/views/LandingPage/LandingPage.vue`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-scss"
      }}>{`@import '../../styles/carbon-utils';
`}</code></pre>
    <p>{`with the other imports and`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-scss"
      }}>{`.landing-page__banner {
  padding-top: $spacing-05;
  padding-bottom: $spacing-07 * 4;
}
`}</code></pre>
    <p>{`Referencing the `}<a parentName="p" {...{
        "href": "https://www.carbondesignsystem.com/guidelines/spacing#spacing-scale"
      }}>{`spacing token table`}</a>{`, `}<inlineCode parentName="p">{`16px`}</inlineCode>{` can be set with the `}<inlineCode parentName="p">{`$spacing-05`}</inlineCode>{` token. The design calls for `}<inlineCode parentName="p">{`128px`}</inlineCode>{` of space below the heading and that’s not in the spacing scale, we can achieve that in Sass by multiplying 32px (`}<inlineCode parentName="p">{`$spacing-07`}</inlineCode>{`) by 4. We could use `}<inlineCode parentName="p">{`128px`}</inlineCode>{` or `}<inlineCode parentName="p">{`8rem`}</inlineCode>{` directly in our styling, but using our tokens preserves consistency should the token values get updated in the future.`}</p>
    <p>{`Looking at the design, we need a wall-to-wall light gray background behind the banner and also behind the third row. This is a great opportunity to use a Sass mixin. We could put this at the top of `}<inlineCode parentName="p">{`LandingPage.vue`}</inlineCode>{`, but it’s best practice to place mixins in a dedicated file, so create a `}<inlineCode parentName="p">{`_mixins.scss`}</inlineCode>{` file in `}<inlineCode parentName="p">{`src/views/LandingPage`}</inlineCode>{`.`}</p>
    <p>{`Add the following in `}<inlineCode parentName="p">{`_mixins.scss`}</inlineCode>{`. Per the design we need to use Gray 10 for our banner background color, which can be set with the `}<inlineCode parentName="p">{`$ui-01`}</inlineCode>{` `}<a parentName="p" {...{
        "href": "https://www.carbondesignsystem.com/guidelines/color/usage"
      }}>{`color token`}</a>{`. Also, we want the background to extend into the grid’s outermost gutters to go the full width of the viewport, so given the DOM structure, we can achieve that by setting the background in an absolutely positioned pseudo element.`}</p>
    <h5 {...{
      "id": "srcviewslandingpage_mixinsscss"
    }}>{`src/views/LandingPage/`}{`_`}{`mixins.scss`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-scss"
      }}>{`@mixin landing-page-background() {
  background-color: $ui-01;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    left: -$spacing-05;
    top: 0;
    right: -$spacing-05;
    bottom: 0;
    background: $ui-01;
    z-index: -1;
  }
}
`}</code></pre>
    <p>{`After you have created `}<inlineCode parentName="p">{`_mixins.scss`}</inlineCode>{`, import it at the `}<strong parentName="p">{`top`}</strong>{` of `}<inlineCode parentName="p">{`LandingPage.vue`}</inlineCode>{`. By now you should have three imports:`}</p>
    <h5 {...{
      "id": "srcviewslandingpagelandingpagevue-8"
    }}>{`src/views/LandingPage/LandingPage.vue`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-scss"
      }}>{`@import '../../styles/carbon-utils';
@import './carbon-overrides';
@import './mixins';
`}</code></pre>
    <p>{`Now to use the new mixin, update the `}<inlineCode parentName="p">{`.landing-page__banner`}</inlineCode>{` declaration block to:`}</p>
    <h5 {...{
      "id": "srcviewslandingpagelandingpagevue-9"
    }}>{`src/views/LandingPage/LandingPage.vue`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-scss"
      }}>{`.landing-page__banner {
  padding-top: $spacing-05;
  padding-bottom: $spacing-07 * 4;
  @include landing-page-background;
}
`}</code></pre>
    <p>{`Next, we can see that the `}<inlineCode parentName="p">{`h1`}</inlineCode>{` is using the `}<inlineCode parentName="p">{`heading-05`}</inlineCode>{` type token.`}</p>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "61.029411764705884%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAAsSAAALEgHS3X78AAAB3ElEQVQoz5WSWW/aUBCF/RtYHpHgKW1pf3giIUHEb8hDlVYkgG1sg22MzY4xmxHb6T1TOYqUh6aWjub6Lt+dM3M1NwzgB0NREAQYquj4HnTDwEurBUPFfr8Px3Fg27bIMHR0uzpcW0fgWgicZwy9PlzXg2arjWEUIgxDjMdjUaQ0n88xm82wWCwQRZGs8380GgnUsix02i9ov7bw2npWUY3bbWjH9IharYb7+wc0Hh/RbDZRr9dlLlOj0RBxzPXVaoXtdot4vcY6SZQ22Gz+SptOp6hWq/j65Q4/qt/wXalUKiGfz6NQKKBYLKJcLqNSqSCXy8le13UFulwusdvtkCgox2t1gXY+ndDpGnj6ZeLpt4WfLRO9Xk8sZeK/aZoyZj1ZFtongDDGxXwhUO12u6nbYuimg46uAM5AAJ7nfYgEsjlZNrSdHlIkmzXmqylixdGu16sU3ledtW0LnrLj+76IEOn8cCgaDAYyfzgckKap2GVMNgmiSSgc7XK5yE3ZJsb32u/3HyItE8w6sge0zfgGzDr0GfEwD04mE6ljHMdSAq5JU7IMOZHFf4lAloBvk++SIkyArOH5fAbBn9FJvQraCxSE2R2Px7dSMCEB/s/HV0GrtMyMMhAbRPAfVONf90/avDUAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Banner heading type",
            "title": "Banner heading type",
            "src": "/static/c7190e1685d5378baf9db14edf1269de/fb070/landing-r1-type.png",
            "srcSet": ["/static/c7190e1685d5378baf9db14edf1269de/d6747/landing-r1-type.png 288w", "/static/c7190e1685d5378baf9db14edf1269de/09548/landing-r1-type.png 576w", "/static/c7190e1685d5378baf9db14edf1269de/fb070/landing-r1-type.png 1152w", "/static/c7190e1685d5378baf9db14edf1269de/55534/landing-r1-type.png 1360w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
        <Caption mdxType="Caption">Banner heading type</Caption>
      </Column>
    </Row>
    <p>{`The Sketch symbol naming is consistent with the development Sass tokens to help translate design to development. So, looking up the `}<a parentName="p" {...{
        "href": "https://www.carbondesignsystem.com/guidelines/typography/productive"
      }}>{`type token`}</a>{`, we know to use `}<inlineCode parentName="p">{`productive-heading-05`}</inlineCode>{`:`}</p>
    <h5 {...{
      "id": "srcviewslandingpagelandingpagevue-10"
    }}>{`src/views/LandingPage/LandingPage.vue`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-scss"
      }}>{`.landing-page__heading {
  @include carbon--type-style('productive-heading-05');
}
`}</code></pre>
    <h3 {...{
      "id": "row-two"
    }}>{`Row two`}</h3>
    <p>{`For our second row, we need to fix the tabs vertical positioning to match the design. By inspecting the tabs component, you can see that the tab height computes to `}<inlineCode parentName="p">{`40px`}</inlineCode>{`. We can use that to create our negative top margin in rem units.`}</p>
    <h5 {...{
      "id": "srcviewslandingpagelandingpagevue-11"
    }}>{`src/views/LandingPage/LandingPage.vue`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-scss"
      }}>{`.landing-page__r2 {
  margin-top: rem(-40px);
}
`}</code></pre>
    <p>{`We also need to adust our vertical spacing and type treatment. Like before, it’s a matter of using spacing and type tokens like so:`}</p>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "58.970588235294116%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAAsSAAALEgHS3X78AAAB60lEQVQoz4VTy27TUBCN+AoWfAtbPoAvQIItOyRWCAkJddV9N6y77CeA2MCmiR+N8/Iztklsp/F92Clt49O51zFN1VQsjubO6M6ZMzP39tA0+MsYPsUJ3scRKs4BIQGKNQQ8hbI8GO8pwmvBcZRO8dGdYZVl2CyWuCoKNER+EIzrYp0PLsi26AGkMGf4+c1G4EyQTsYILBvlfA5UNSArsoS6fmiFaM9StqRij/B6JTA6S7DyQrAkRu75uAxDrIIQl1GEzPOwmM20XbouMtfDmuLJeAz5Z6ELdEp7DbV8yxlS/wKuYSAaDhHYNryBAd+yEI9GCJVvmvAME5Pzc/imhZC6UHba76OM439KW0KaR2Q61OoF5o6jk1Oqrs6q/XQ80WqU3xVwBwNMiXw+dHCrFtS1rAi3xByHFmY2XRqQNSxSo9CqVElKvSqkfD0OP9AjWNOsH83wZs3R/1KA/SqAm4zkky0z1Grjea43vsly1MtMn/VS5N6C9IbVDHlLeFVwfHglcfRV4vSHxMmZxPffdKES+rKG2EElU4vNmlCyFrs32Oh3SIQVbfnlZ4nn7yRevJV49lrizTElbzi27PBbhI7vFdxht2VBMxTwPYEgaG2e3M/lQaJWyduf8RThVj9SpagDJUh2UMH/CO8AS/CAmJjIwKEAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Row 2 vertical spacing",
            "title": "Row 2 vertical spacing",
            "src": "/static/33ed6696287eb1b5eb002b64b02773f7/fb070/landing-r2-spacing.png",
            "srcSet": ["/static/33ed6696287eb1b5eb002b64b02773f7/d6747/landing-r2-spacing.png 288w", "/static/33ed6696287eb1b5eb002b64b02773f7/09548/landing-r2-spacing.png 576w", "/static/33ed6696287eb1b5eb002b64b02773f7/fb070/landing-r2-spacing.png 1152w", "/static/33ed6696287eb1b5eb002b64b02773f7/55534/landing-r2-spacing.png 1360w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
        <Caption mdxType="Caption">Row 2 vertical spacing</Caption>
      </Column>
    </Row>
    <p><em parentName="p">{`Note: You may be wondering why there are vertical gaps between the type and spacers. Each type token has a line height that’s suited for its font size. The vertical spacers adjacently touch the line height boundaries and not the baseline, for consistency as well as development ease so`}</em>{` `}<inlineCode parentName="p">{`margins`}</inlineCode>{` `}<em parentName="p">{`and`}</em>{` `}<inlineCode parentName="p">{`paddings`}</inlineCode>{` `}<em parentName="p">{`don’t need to offset line heights.`}</em></p>
    <h5 {...{
      "id": "srcviewslandingpagelandingpagevue-12"
    }}>{`src/views/LandingPage/LandingPage.vue`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-scss"
      }}>{`.landing-page__tab-content {
  padding-top: $layout-05;
  padding-bottom: $layout-05;
}

.landing-page__subheading {
  @include carbon--type-style('productive-heading-03');
  @include carbon--font-weight('semibold');
}

.landing-page__p {
  @include carbon--type-style('productive-heading-03');
  margin-top: $spacing-06;
  margin-bottom: $spacing-08;

  @include carbon--breakpoint-between((320px + 1), md) {
    max-width: 75%;
  }
}
`}</code></pre>
    <h3 {...{
      "id": "row-three"
    }}>{`Row three`}</h3>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "24.558823529411764%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAYAAABFA8wzAAAACXBIWXMAAAsSAAALEgHS3X78AAAAzUlEQVQY022OwW6CQBRF/Ue/ogu/q1111Y2LfkGXNilpaqIUZhAxnUEQQZjjk9GIDYuTm3fffTdv4pyjsznkFmcFY0EUY7wOcPaecSP7CxPoOOiM96eScC7mIaPSKcdkQ7VJHzimW+pt5vm3uyGFjn2c8DZb8vG8IP5eUESKMo4plajSXrXGrENUEKC+ArKfpTyiBxnPtTDldbrj80VR7EKZfbCQgxulTtiLZ3+jnjyKem+YudAXnuqGv1VLZVq67kRT1+KN0DQ97VXHcmfh+XpnJJLtvgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Row 3 vertical spacing",
            "title": "Row 3 vertical spacing",
            "src": "/static/014fc9b6ccd2fa4a8691bb8abd966c94/fb070/landing-r3-spacing.png",
            "srcSet": ["/static/014fc9b6ccd2fa4a8691bb8abd966c94/d6747/landing-r3-spacing.png 288w", "/static/014fc9b6ccd2fa4a8691bb8abd966c94/09548/landing-r3-spacing.png 576w", "/static/014fc9b6ccd2fa4a8691bb8abd966c94/fb070/landing-r3-spacing.png 1152w", "/static/014fc9b6ccd2fa4a8691bb8abd966c94/55534/landing-r3-spacing.png 1360w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
        <Caption mdxType="Caption">Row 3 vertical spacing</Caption>
      </Column>
    </Row>
    <p>{`Let’s also add some styles for the last row, even though that will get used later in the tutorial. You’ll notice that we get to re-use the `}<inlineCode parentName="p">{`landing-page-background`}</inlineCode>{` mixin that we just created.`}</p>
    <h5 {...{
      "id": "srcviewslandingpagelandingpagevue-13"
    }}>{`src/views/LandingPage/LandingPage.vue`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-scss"
      }}>{`.landing-page__r3 {
  padding-top: $spacing-09;
  padding-bottom: $spacing-09;
  @include landing-page-background;
}

.landing-page__label {
  @include carbon--type-style('heading-01');
}
`}</code></pre>
    <p>{`Ta-da! You should see a finished landing page! Now we can move on to the repo page.`}</p>
    <h2 {...{
      "id": "add-repo-page-grid"
    }}>{`Add repo page grid`}</h2>
    <p>{`Now in our `}<inlineCode parentName="p">{`RepoPage.vue`}</inlineCode>{` we’ll add our grid containers in the template section.`}</p>
    <h5 {...{
      "id": "srcviewsrepopagerepopagevue"
    }}>{`src/views/RepoPage/RepoPage.vue`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<template>
  <div class="bx--grid bx--grid--full-width bx--grid--no-gutter repo-page">
    <div class="bx--row repo-page__r1">
      <div class="bx--col-lg-16">Data table will go here</div>
    </div>
  </div>
</template>
`}</code></pre>
    <h2 {...{
      "id": "build-repo-page"
    }}>{`Build repo page`}</h2>
    <p>{`We currently have `}<inlineCode parentName="p">{`RepoPage.vue`}</inlineCode>{` that just contains a grid and placeholder content for the time being. In the next tutorial step we’re going to be querying an API to populate the `}<inlineCode parentName="p">{`CvDataTable`}</inlineCode>{` component in this page. As a best practice to separate data fetching from the presentation components, go ahead and create a `}<inlineCode parentName="p">{`RepoTable.vue`}</inlineCode>{` as a sibling to `}<inlineCode parentName="p">{`RepoPage.vue`}</inlineCode>{` in `}<inlineCode parentName="p">{`src/views/RepoPage`}</inlineCode>{`.`}</p>
    <h3 {...{
      "id": "build-data-table"
    }}>{`Build data table`}</h3>
    <p>{`First, let’s pretend we’ve already built our table component and update `}<inlineCode parentName="p">{`RepoPage.vue`}</inlineCode>{` with a script section.`}</p>
    <p>{`Import `}<inlineCode parentName="p">{`RepoTable`}</inlineCode>{` in `}<inlineCode parentName="p">{`RepoPage.vue`}</inlineCode>{`.`}</p>
    <h5 {...{
      "id": "srcviewsrepopagerepopagevue-1"
    }}>{`src/views/RepoPage/RepoPage.vue`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`<script>
import RepoTable from "./RepoTable";

export default {
  name: "RepoPage"
};
</script>
`}</code></pre>
    <p>{`Then below the import, include the following arrays to pass into the `}<inlineCode parentName="p">{`RepoTable`}</inlineCode>{` component. We’ll be setting the `}<inlineCode parentName="p">{`rows`}</inlineCode>{` array from an API in the next tutorial step, but for now, static example rows will suffice.`}</p>
    <h5 {...{
      "id": "srcviewsrepopagerepopagevue-2"
    }}>{`src/views/RepoPage/RepoPage.vue`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const headers = [
  {
    key: 'name',
    header: 'Name',
  },
  {
    key: 'createdAt',
    header: 'Created',
  },
  {
    key: 'updatedAt',
    header: 'Updated',
  },
  {
    key: 'issueCount',
    header: 'Open Issues',
  },
  {
    key: 'stars',
    header: 'Stars',
  },
  {
    key: 'links',
    header: 'Links',
  },
];

const rows = [
  {
    id: '1',
    name: 'Repo 1',
    createdAt: 'Date',
    updatedAt: 'Date',
    issueCount: '123',
    stars: '456',
    links: 'Links',
  },
  {
    id: '2',
    name: 'Repo 2',
    createdAt: 'Date',
    updatedAt: 'Date',
    issueCount: '123',
    stars: '456',
    links: 'Links',
  },
  {
    id: '3',
    name: 'Repo 3',
    createdAt: 'Date',
    updatedAt: 'Date',
    issueCount: '123',
    stars: '456',
    links: 'Links',
  },
];
`}</code></pre>
    <p>{`Next we need to make sure the RepoTable component and these arrays are available to our `}<inlineCode parentName="p">{`RepoPage`}</inlineCode>{` component template. Your component export should look like the following to achieve this.`}</p>
    <h5 {...{
      "id": "srcviewsrepopagerepopagevue-3"
    }}>{`src/views/RepoPage/RepoPage.vue`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`export default {
  name: 'RepoPage',
  components: { RepoTable },
  data() {
    return {
      headers,
      rows,
    };
  },
};
`}</code></pre>
    <p>{`Here we used the data method of the component as the values logically are data in our component.`}</p>
    <p>{`Lastly in the `}<inlineCode parentName="p">{`RepoPage.vue`}</inlineCode>{` template, we need to simply replace `}<inlineCode parentName="p">{`Data table will go here`}</inlineCode>{` with:`}</p>
    <h5 {...{
      "id": "srcviewsrepopagerepopagevue-4"
    }}>{`src/views/RepoPage/RepoPage.vue`}</h5>
    {
      /* prettier-ignore-start */
    }
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<repo-table
  :headers="headers"
  :rows="rows"
  title="Carbon Repositories"
  helperText="A collection of public Carbon repositories."
/>
`}</code></pre>
    {
      /* prettier-ignore-end */
    }
    <p>{`OK. So now our `}<inlineCode parentName="p">{`RepoPage.vue`}</inlineCode>{` component is ready to use a component called ‘RepoTable’ so let’s create it so our page displays again.`}</p>
    <p>{`First create a script section as follows:`}</p>
    <h5 {...{
      "id": "srcviewsrepopagerepotablevue"
    }}>{`src/views/RepoPage/RepoTable.vue`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`<script>
  export default {
    name: 'RepoTable',
    props: {
      headers: Array,
      rows: Array,
      title: String,
      helperText: String,
    },
    computed: {
      columns() {
        return this.headers.map(header => header.header);
      },
      data() {
        return this.rows.map(row => ({
          data: [
            row.name,
            row.createdAt,
            row.updatedAt,
            row.issueCount,
            row.stars,
            row.links,
          ],
          description: 'Row description',
        }));
      },
    },
  };
</script>
`}</code></pre>
    <p>{`In this component script we:`}</p>
    <ul>
      <li parentName="ul">{`Named our new component `}<inlineCode parentName="li">{`RepoTable`}</inlineCode>{`.`}</li>
      <li parentName="ul">{`Added component properties for the headers and rows as well as two further properties title and helperText.`}</li>
      <li parentName="ul">{`Added the computed properties columns and headers which transform headers and rows into a format convenient for rendering with the CvDataTable components.`}</li>
    </ul>
    <p>{`Next, let’s create the `}<inlineCode parentName="p">{`RepoTable`}</inlineCode>{` template starting with the cv-data-table component.`}</p>
    <h5 {...{
      "id": "srcviewsrepopagerepotablevue-1"
    }}>{`src/views/RepoPage/RepoTable.vue`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<template>
  <cv-data-table :columns="columns" :title="title" :helper-text="helperText">
  </cv-data-table>
</template>
`}</code></pre>
    <p>{`Here we pass in the columns, title and helper text. If you view this you will see an empty table with headings.`}</p>
    <p>{`Next add the rows inside of cv-data-table.`}</p>
    {
      /* prettier-ignore-start */
    }
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<template slot="data">
  <cv-data-table-row v-for="(row, rowIndex) in data" :key="\`\${rowIndex}\`"> </cv-data-table-row>
</template>
`}</code></pre>
    {
      /* prettier-ignore-end */
    }
    <p>{`Here we use v-for to iterate through the data assigning a key and value to each row. The rendered output may not appear
to have changed, although if you inspect using the developer tools you will find three empty rows. Next add the cell
data inside the `}<inlineCode parentName="p">{`cv-data-table-row`}</inlineCode>{` component.`}</p>
    {
      /* prettier-ignore-start */
    }
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<cv-data-table-cell
  v-for="(cell, cellIndex) in row.data"
  :key="\`\${cellIndex}\`"
  >{{cell}}</cv-data-table-cell
>
`}</code></pre>
    {
      /* prettier-ignore-end */
    }
    <p>{`Again we’ve used the v-for directive to iterate through our data adding key, value and the contents of the cell.`}</p>
    <p>{`The last item we need to add is the description shown in the expanded content. Add the following as a sibling of cv-data-table-cell component.`}</p>
    {
      /* prettier-ignore-start */
    }
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<template slot="expandedContent">
{{ row.description }}
</template>
`}</code></pre>
    {
      /* prettier-ignore-end */
    }
    <p>{`Now you should have a working page rendering the sample content from the rows array.`}</p>
    <p>{`This component consumes our properties and returns a Carbon `}<inlineCode parentName="p">{`CvDataTable`}</inlineCode>{`. As for where the various `}<inlineCode parentName="p">{`CvDataTable*`}</inlineCode>{` components came from? The `}<a parentName="p" {...{
        "href": "http://vue.carbondesignsystem.com/?path=/story/components-cvdatatable--default"
      }}>{`CvDataTable story`}</a>{` in Storybook was used to put together the data table structure.`}</p>
    <h2 {...{
      "id": "style-repo-page"
    }}>{`Style repo page`}</h2>
    <p>{`Our styles for the repo page are mostly fine. We just need to update a few vertical spacing issues.`}</p>
    <p>{`In `}<inlineCode parentName="p">{`RepoPage.vue`}</inlineCode>{`, add the following style section:`}</p>
    <h5 {...{
      "id": "srcviewsrepopagerepopagevue-5"
    }}>{`src/views/RepoPage/RepoPage.vue`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-scss"
      }}>{`<style lang="scss">
@import '../../styles/carbon-utils';

.repo-page .bx--row {
  padding-top: $spacing-05;
  padding-bottom: $spacing-05;
}
</style>
`}</code></pre>
    <p>{`Congratulations! We’ve now created our static repo page!`}</p>
    <h2 {...{
      "id": "submit-pull-request"
    }}>{`Submit pull request`}</h2>
    <p>{`We’re going to submit a pull request to verify completion of this tutorial step.`}</p>
    <h3 {...{
      "id": "continuous-integration-ci-check"
    }}>{`Continuous integration (CI) check`}</h3>
    <p>{`Run the CI check to make sure we’re all set to submit a pull request.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ yarn ci-check
`}</code></pre>
    <p><em parentName="p">{`Note: Having issues running the CI check? `}<a parentName="em" {...{
          "href": "/tutorial/vue/step-1#continuous-integration-ci-check"
        }}>{`Step 1`}</a>{` has troubleshooting notes that may help.`}</em></p>
    <h3 {...{
      "id": "git-commit-and-push"
    }}>{`Git commit and push`}</h3>
    <p>{`Before we can create a pull request, stage and commit all of your changes:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ git add --all && git commit -m "feat(tutorial): complete step 2"
`}</code></pre>
    <p>{`Then, push to your repository:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ git push origin vue-step-2
`}</code></pre>
    <p><em parentName="p">{`Note: Having issues pushing your changes? `}<a parentName="em" {...{
          "href": "/tutorial/vue/step-1#git-commit-and-push"
        }}>{`Step 1`}</a>{` has troubleshooting notes that may help.`}</em></p>
    <h3 {...{
      "id": "pull-request-pr"
    }}>{`Pull request (PR)`}</h3>
    <p>{`Finally, visit `}<a parentName="p" {...{
        "href": "https://github.com/carbon-design-system/carbon-tutorial-vue"
      }}>{`carbon-tutorial-vue`}</a>{` to “Compare & pull request”. In doing so, make sure that you are comparing to `}<inlineCode parentName="p">{`vue-step-2`}</inlineCode>{` into `}<inlineCode parentName="p">{`base: vue-step-2`}</inlineCode>{`.`}</p>
    <p><em parentName="p">{`Note: Expect your tutorial step PRs to be reviewed by the Carbon team but not merged. We’ll close your PR so we can keep the repository’s remote branches pristine and ready for the next person!`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      